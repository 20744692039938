const PowLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xml:space="preserve"
      width="13px"
      height="13px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        marginLeft: 5,
        // imageRendering: "optimizeQuality",
      }}
      viewBox="0 0 689 795"
      fill="grey"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0 str0"
          d="M334 777c-81,17 -168,3 -239,-52 -62,-49 -94,-118 -72,-209 11,-47 63,-98 120,-132 26,-16 108,-56 132,-58 56,-6 109,18 158,49 24,15 43,36 61,57 26,31 40,62 44,92 17,130 -79,227 -203,254z"
        />
        <path
          className="fil0 str0"
          d="M167 66c24,22 34,45 41,64 10,29 10,60 -8,88 -22,34 -42,69 -60,107 -5,10 -15,25 -31,35 -19,11 -41,10 -61,-10 -19,-19 -31,-46 -35,-79 -8,-61 5,-111 25,-155 26,-44 76,-99 129,-50z"
        />
        <path
          className="fil0 str0"
          d="M567 130c-30,13 -47,31 -58,48 -19,25 -26,53 -17,86 10,39 19,79 25,121 1,12 5,29 17,43 15,17 36,22 62,9 24,-12 42,-34 57,-64 26,-56 29,-109 24,-157 -11,-54 -42,-115 -109,-86z"
        />
        <path
          className="fil0 str0"
          d="M450 120c3,40 -1,83 -22,125 -12,23 -33,29 -52,31 -27,3 -52,-3 -77,-13 -17,-8 -29,-24 -34,-42 -8,-35 -1,-70 11,-108 13,-38 33,-72 56,-86 72,-49 114,36 119,94z"
        />
      </g>
    </svg>
  );
};

export default PowLogoIcon;
