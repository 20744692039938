import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  descriptionLists_1,
  descriptionLists_2,
  descriptionLongText,
  teamDescription,
} from "../utils/index";
import DescriptionBlock from "./DescriptionBlock";
import SocialMediaRow from "./SocialMediaRow";

const Description = ({ text }: { text: string }) => {
  return (
    <Typography className="mb-10 text-white" style={{ textAlign: "justify" }}>
      {text}
    </Typography>
  );
};

const TeamDescription = ({ person }: { person: any }) => {
  return (
    <Grid
      xs={12}
      md={4}
      className={
        person.side === "left" ? "teamDescriptionLeft" : "teamDescriptionRight"
      }
    >
      <Typography variant="h5" className="teamDescriptionName">
        {person.name}
      </Typography>
      <Typography className="teamDescriptionText">{person.text}</Typography>
    </Grid>
  );
};

export const ProjectDescription = () => {
  return (
    <Container style={{ marginTop: "4rem" }}>
      <Typography variant="h3" className="projectDescriptionHeading">
        PixPal – building a community <br /> where creativity flourishes
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className="socialMediaIconsRow"
      >
        <SocialMediaRow size="lg" />
      </Grid>
      <Grid container direction="row" justifyContent="center">
        {descriptionLongText.map((text: string, index: number) => {
          return <Description key={index} text={text} />;
        })}
      </Grid>
      <Grid container spacing={3} className="mt-30">
        {descriptionLists_1.map((item: any, index: number) => {
          return (
            <DescriptionBlock
              key={index}
              index={index}
              label={item.label}
              description={item.description}
            />
          );
        })}
      </Grid>
      <Grid container spacing={3}>
        {descriptionLists_2.map((item: any, index: number) => {
          return (
            <DescriptionBlock
              key={index + 3}
              index={index + 3}
              label={item.label}
              description={item.description}
            />
          );
        })}
      </Grid>
      <Container className="text-center" style={{ marginTop: "4em" }}>
        <Typography variant="h3" className="projectDescriptionHeading">
          Team
        </Typography>
      </Container>
      <Container className="mt-30">
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginBottom: "4em" }}
        >
          <Grid xs={12} md={4} className="text-center teamDescriptionLeft">
            <img src="/dog_01.png" width="270" alt="PixPal Byte Dog" />
          </Grid>
          <TeamDescription person={teamDescription.ant} />
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <TeamDescription person={teamDescription.deedee} />
          <Grid
            xs={12}
            md={4}
            className="teamDescriptionRight"
            style={{ textAlign: "right" }}
          >
            <img src="/bear_01.png" width="300" alt="PixPal Lucky Bear" />
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
