export const descriptionLists_1 = [
    {label: "NFT Collection", description: "PixPal’s initial NFT collection is crucial to our success. It is the gateway by which members enter our platform and explore the exciting world of NFTs. At our launch stage, in 2022, we will be integrating 3 brand new NFT collections - with main characters &quot;Byte&quot; the dog, &quot;Lucky&quot; the bear, and &quot;Croaky&quot; the frog. There will be 9,999 items in total, and each item will be unique. <br/> <br/> Owners of these items will become main shareholders and investors in PixPals and will receive multiple perks as well as share in the profits collected by the platform."},
    {label: "NFT Marketplace", description: "PixPal wholeheartedly believes in the viability and competitive nature of the Solana’s blockchain. This is the main reason why we’ve picked this platform over others — more benefits, and a very low pass fee. The principal ones of the initial NFT collection will receive profits from all items sold on the PixPal platform. Marketplace fees, taken from the selling price of every transaction, will also be divided among the creators and investors of PixPal. 40% of all profits will be funded to cover infrastructure and security costs — the rest, 60%, will be shared by NFT owners. <br /><br /> The amount of profit an investor gets will depend on the rarity of their NFT items and the size of their collection."},
    {label: "Hybrid Items Shop", description: "PixPal will be inaugurating physical counterparts of its online shop. In these stores consumers will be able to buy t-shirts, wallets, cups, etc. Each physical item will have an NFT certificate proving its authenticity, and will be securely stored in the Solana blockchain. <br /><br /> PixPal’s initial investors - the owners of the first wave of NFTs - will also get shares from the profit obtained in the offline store. <br /><br /> Once the technology has been perfected, and the platform worked over, the hybrid stores will be open to the public. Allowing individuals to sell their own NFTs along with physical items/ or representations of it."},
]

export const descriptionLists_2 = [
    {label: "Metaverse Art Galleries", description: "Unique collectibles and the best items - from aspiring artists - will be handpicked by the DAO community. For each of those items, the PixPal team will create a once-in-a-lifetime NFT. These NFTs will be curated and made available as meta verse collectibles. <br /><br /> The original owners of the physical art pieces will get a lifetime&apos;s worth of royalties — 25%."},
    {label: "PixPal Art Galleries", description: "Our biggest and most brazen push will come when PixPal begins to open physical art galleries around the world — particularly in Capital cities and world renowned metropolises. Consumers will have the ability to discover their favorite NFTs virtually, while simultaneously being able to purchase a copy of it in an actual tangible store. <br /><br /> The galleries themselves are being designed to have a modern minimalistic look. Customers will be able to browse NFT collections since PixPal will be adding interactive tools to it. Each gallery will have its own feel, and vibe — customers will be able to explore, be educated, and interact with the world of NFTs and have a fully immersive experience outside of the digital, or virtual."},
    {label: "Crypto Coin/Token", description: "For our initial launch - our 3 main NFT characters - PixPal will also spearhead a massive crypto coin or token push. Each character will have a corresponding digital currency to it."},
]

export const descriptionLongText = [
    "PixPal is the first ever community driven platform that efficiently and dynamically mixes the benefits of the crypto world, and NFTs, with the practicality of the tangible. A marriage of traditional platforms with innovative solutions and investment possibilities.",
    "PixPal is an easy to use platform whose sole objective is to simplify the world of digital currencies, artwork and products — a revolutionary podium aimed at everyone, even those that have no experience in the world of blockchain and cryptos.",
    "A centralized governance with a Profit-Sharing Community, PixPal combines the digital of NFTS and cryptos with the tangible value of the real-world art-galleries. Our aim is to create a NFT collection that can easily transition into a brick and mortar store. One that, overtime, will incorporate other products such as its own cryptocurrency, tokens, and games.",
    "Our first stage is set into high gear by incorporating 3 brand new NFT collections. These are our entry points and how members will be able to explore and invest in the world of PixPal. It is an energetic coming of age stage, lively, that incorporates a powerful marketplace, and brand merchandise. This stage is backed by NFT’s from some of today’s hottest artists.",
    "PixPal Art Galleries will be accessible inside as well as outside the Metaverse. Physical art-galleries, where members can access their investments, will be opened in capital cities across the world."
]

export const teamDescription = {
    ant: {
        side: 'left',
        name: "Ant — CEO & Founder",
        text: "Ant is the VP of Engineering in one the world’s most famous unicorns, Carsome. He is an ex-CTO and passionate entrepreneur, He’s been at the forefront of leading Crypto projects for the last couple of years. “I think I have a mission in life — to make cryptos and NFTs user-friendly. To somehow onboard society and make them embrace their essence and benefits. And take out all the jargon inherent to the industry.”"
    },
    deedee: {
        side: 'right',
        name: "Dee Dee — Co-Founder",
        text: "Dee Dee is an agar and fervent entrepreneur that embodies today’s spirit and modernity’s ambitious soul. By day a Web2 engineer and ardent supporter of FinTech and Cryptos. “I thrive on my innate ability, something I think I got from my parents, to communicate efficiently. To boil down high-concepts into easy to digest ideas.”"
    }
}