import Logo from "./Logo";
import SocialMediaRow from "./SocialMediaRow";
import Grid from "@material-ui/core/Grid";

const Navbar = () => {
  return (
    <Grid container direction="row" wrap="nowrap" className="mb-40">
      <Grid item xs="auto" md={4}>
        {/* Intentionally Empty */}
      </Grid>
      <Grid item xs={6} md={4} container justifyContent="center">
        <Logo width="70%" />
      </Grid>
      <Grid item xs={6} md={4} container justifyContent="flex-end">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          className="socialMediaIconsRowNav"
        >
          <SocialMediaRow size="sm" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navbar;
