import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import DiscordIcon from "./DiscordIcon";
import InstagramIcon from "@material-ui/icons/Instagram";

const SocialMediaRow = ({ size }: { size: string }) => {
  return (
    <>
      <a href="https://www.pixpal.io/" className="iconSocialMediaWrapper">
        <LanguageIcon className={`iconSocialMedia ${size}`} />
      </a>
      <a
        href="https://twitter.com/pixpal_io"
        className="iconSocialMediaWrapper"
      >
        <TwitterIcon className={`iconSocialMedia ${size}`} />
      </a>
      <a href="https://discord.gg/pixpal" className="iconSocialMediaWrapper">
        <DiscordIcon size={size} />
      </a>
      <a
        href="https://www.youtube.com/c/PixPal_IO"
        className="iconSocialMediaWrapper"
      >
        <YouTubeIcon className={`iconSocialMedia ${size}`} />
      </a>
      <a
        href="https://instagram.com/pixpal.io"
        className="iconSocialMediaWrapper"
      >
        <InstagramIcon className={`iconSocialMedia ${size}`} />
      </a>
    </>
  );
};

export default SocialMediaRow;
