import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

const DescriptionBlock = ({
  index,
  label,
  description,
}: {
  index: number;
  label: string;
  description: string;
}) => {
  return (
    <Grid item xs key={index}>
      <Grid
        container
        direction="row"
        style={{ marginBottom: 15, alignItems: "center" }}
      >
        <Avatar className="iconNumber">
          <b>
            <span>{index + 1}</span>
          </b>
        </Avatar>
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
          }}
          className="text-white"
        >
          {label}
        </Typography>
      </Grid>

      <Typography
        className="mb-40 text-white"
        style={{ textAlign: "justify" }}
        dangerouslySetInnerHTML={{
          __html: `<p>${description}</p>`,
        }}
      ></Typography>
    </Grid>
  );
};

export default DescriptionBlock;
